
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonItem, IonInput, IonLabel, IonTextarea, IonButton, toastController, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { closeSharp, closeOutline } from 'ionicons/icons';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'InseratCreateStep1',
  data () {
    return {
      dataFromPreviousStepIsCollected: false,
      title: "",
      description: "",
      category: "",
      categories: [],
      totalCategories: 0,
      errorWhileLoading: false,
      closeIconIos: closeOutline,
      closeIconMd: closeSharp
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonLabel,
    IonTextarea,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonIcon
  },
  created() {

    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){
        // ready to create inserat
    } else {
      // push to main
      this.$router.push({ path: '/login' });
    }

    // check user permission
    if (!localStorage.userRoles.includes('ROLE_STAR')){
      this.$router.push({ path: '/login' });
    }

    // check if data is collected from previous steps
    if (!localStorage.inseratType){
        this.$router.push({ path: '/inserat/create' });
    }

    // check step
    if (localStorage.inseratStep !== 1){
        // redirect to right step
        if (localStorage.inseratStep == 0){
          this.$router.push({ path: '/inserat/create' });
        } else {
          this.$router.push({ path: '/inserat/create/step-' + localStorage.inseratStep });
        }
    }
  },
  mounted() {

    // get categories
    axios.get(process.env.VUE_APP_API_URL + '/categories')
        .then(response => {
          this.categories = response.data["hydra:member"];
          this.totalCategories = response.data["hydra:totalItems"];
        })
        .finally(() => {
            if (this.totalCategories == 0){
                this.openToast("Etwas ist schiefgelaufen. Probiere es später nochmal.", 10000);
                this.errorWhileLoading = true;
            }
        });

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    closeInseratCreation(){
        // reset inserat local storage
        localStorage.inseratStep = 0;
        localStorage.inseratType = null;
        localStorage.inseratTitle = null;
        localStorage.inseratDescription = null;
        localStorage.inseratPrice = null;
        localStorage.inseratCurrency = null;
        localStorage.inseratIsGoodCause = null;
        localStorage.inseratDonationRecipient = null;
        localStorage.inseratDonationRecipientId = null;
        localStorage.inseratCategoryId = null;
        localStorage.persistedInserat = null;
        localStorage.persistedImage = null;
        // push router to all inserats
        this.$router.push({ path: '/inserat/create'})
    },
    nextStep() {
        let errors = 0;

        // check inputs
        if (!this.title) {
          errors++;
          this.openToast("Du musst einen Titel angeben.", 2000);
        }

        if (!this.description) {
          errors++;
          this.openToast("Du musst einen Beschreibungstext angeben.", 2000);
        }

        if (this.description && this.description.length > 251){
          errors++;
          this.openToast("Beschreibungstext darf nicht länger als 250 Zeichen sein.", 2000);
        }

        if (!this.category){
          errors++;
          this.openToast("Du musst eine Kategorie angeben.", 2000);
        }

        if (errors == 0){
          // save in localStorage
          localStorage.inseratTitle = this.title;
          localStorage.inseratDescription = this.description;
          localStorage.inseratCategoryId = this.category;

          // if inserat is gift, skip to step 4 (uploading inserat and then upload image)
          if (localStorage.inseratType === 'gift'){
              // set default values
              localStorage.inseratPrice = 0;
              localStorage.inseratCurrency = "€";
              localStorage.inseratIsGoodCause = false;
              localStorage.inseratStep = 4;
              this.$router.push({ path: '/inserat/create/step-4' });

              return;
          }

          // go to next step
          localStorage.inseratStep = 2;
          this.$router.push({ path: '/inserat/create/step-2' })
        }

    }
  }
});

